function toggleClass(elem, className) {
    var newClass = ' ' + elem.className.replace( /[\t\r\n]/g, ' ' ) + ' ';
    if (hasClass(elem, className)) {
        while (newClass.indexOf(' ' + className + ' ') >= 0 ) {
            newClass = newClass.replace( ' ' + className + ' ' , ' ' );
        }
        elem.className = newClass.replace(/^\s+|\s+$/g, '');
    } else {
        elem.className += ' ' + className;
    }
}

function addClass(elem, className) {
    if (!hasClass(elem, className)) {
        elem.className += ' ' + className;
    }
}

function hasClass(elem, className) {
    return new RegExp(' ' + className + ' ').test(' ' + elem.className + ' ');
}

var classname = document.getElementsByClassName("expanding-section-header");

var handleExpander = function() {
	console.log(this);
	console.log(this.className);
    toggleClass(this.nextElementSibling, 'active');
    toggleClass(this, 'active');
};

for (var i = 0; i < classname.length; i++) {
	console.log(classname[i]);
    classname[i].addEventListener('click', handleExpander, false);
}